import React, { useContext, useEffect, useRef, useState } from 'react';
import { Col, Row, Button, Input, Upload, Form, DatePicker, notification, Card } from 'antd';
import styled from "styled-components";
import { style } from "./styles";
import { gapi } from "gapi-script";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import TextArea from "antd/es/input/TextArea";
import { UserContext } from '../App';
import { CategorySelect } from './CategorySelect';
import axios from 'axios';

const { Search } = Input;

const NewDocumentWrapper = styled.div`
  ${style}
`;

const Uploader = ({ user, visible, onClose, documents = [], onSearch, isLoading }) => {

    const [signedInUser, setSignedInUser] = useState();
    const [loading, setLoading] = useState(false);
    const formRef = useRef();
    const userContext = useContext(UserContext);

    useEffect(
        () => {
            if (!signedInUser) {
                axios
                    .get(`https://www.googleapis.com/oauth2/v3/userinfo?access_token=${userContext.access_token}`, {
                        headers: {
                            Authorization: `Bearer ${userContext.access_token}`,
                            Accept: 'application/json'
                        }
                    })
                    .then((res) => {
                        console.log('signedInUser', res.data);
                        setSignedInUser(res.data);
                    })
                    .catch((err) => console.log(err));

                (async () => {


                    // First, load and initialize the gapi.client
                    await new Promise((resolve, reject) => {
                        // NOTE: the 'auth2' module is no longer loaded.
                        gapi.load('client', { callback: resolve, onerror: reject });
                    });
                    await gapi.client.init({
                        // NOTE: OAuth2 'scope' and 'client_id' parameters have moved to initTokenClient().
                    })
                        .then(function () {  // Load the Calendar API discovery document.
                            gapi.client.load('https://www.googleapis.com/discovery/v1/apis/sheets/v4/rest');
                        });

                })();
            }
        },
        []
    );


    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const handleChange = info => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            console.log(info.file.originFileObj);
            alert("test");
            setLoading(false);
        }
    };

    const beforeUpload = file => {

        return false;
    };

    const addValues = (obj) => {
        if (obj?.file && obj?.file[0]) {
            const fileExtension = obj?.file[0].name.substr(obj?.file[0].name.lastIndexOf('.') + 1, obj?.file[0].name.length);
            const metadata = {
                name: `${obj.date.format('YYYYMMDD')}_${new Date().getTime()}.${fileExtension}`,
                parents: [process.env.REACT_APP_PARENT_GDRIVE_FOLDER]
            };

            const fd = new FormData();
            fd.append('metadata', new Blob([JSON.stringify(metadata)], { type: 'application/json' }));
            fd.append('file', obj.file[0].originFileObj);
            fetch('https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart', {
                method: 'POST',
                headers: { Authorization: 'Bearer ' + userContext.access_token },
                body: fd
            })
                .then(response => response.json())
                .then(jsonResp => {
                    console.log(jsonResp);
                    notification['success']({
                        message: 'Success',
                        description:
                            'File added to drive',
                    });


                    const obj1 = {
                        ...obj,
                        id: jsonResp.id,
                        name: jsonResp.name
                    }

                    addRow(obj1);

                }).catch(err => {
                    console.error(err);
                    notification['error']({
                        message: 'Error',
                        description:
                            'Error occurred adding the file to drive',
                    });
                }
                );
        } else {

            addRow(obj);
        }

    }

    // Make sure the client is loaded and sign-in is complete before calling this method.
    const addRow = (obj) => {
        const values = [[obj.date.format('DD/MM/YYYY'),
        obj.description,
        obj.category,
        obj.note,
        obj.amount,
        obj.id && `=HYPERLINK(\"https://drive.google.com/file/d/${obj.id}/view\"; \"${obj.name}\")`]];

        return fetch(`https://sheets.googleapis.com/v4/spreadsheets/${process.env.REACT_APP_PARENT_GDRIVE_SHEET}/values/${'Ricevute'}:append`
            + `?valueInputOption=USER_ENTERED&responseValueRenderOption=UNFORMATTED_VALUE&responseDateTimeRenderOption=FORMATTED_STRING&insertDataOption=INSERT_ROWS`, {
            method: 'POST',
            headers: { Authorization: 'Bearer ' + userContext.access_token },
            body: JSON.stringify({
                "range": 'Ricevute',
                "majorDimension": 'ROWS',
                "values": values
            })
        })
            .then(response => response.json())
            .then(jsonResp => {
                console.log("Response", jsonResp);
                notification['success']({
                    message: 'Success',
                    description:
                        'Row added to spreadsheet',
                });
                formRef.current.resetFields();
            }).catch(err => {
                console.error("Execute error", err)
                notification['error']({
                    message: 'Error',
                    description:
                        'Error occurred adding row to spreadsheet',
                });
            }
            );

        
    }

    const onFinish = (values) => {
        console.log(values);
        addValues(values);
    };

    const getFile = (e) => {
        console.log('Upload event:', e);

        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };
    return (
        <NewDocumentWrapper>
            <div className="custom-row">
                <Row gutter={16}>
                    <Col span={24}>
                        <div style={{ marginBottom: 20 }}>
                            <p>Signed In as: {`${signedInUser?.name} (${signedInUser?.email})`}</p>

                        </div>

                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <Card className="app-card" style={{ alignSelf: "center" }}>
                                <div>
                                    <Form ref={formRef} name="control-ref"
                                        onFinish={onFinish}>
                                        <Form.Item name="date" label="Date" rules={[{ required: true }]}>
                                            <DatePicker format={'DD/MM/YYYY'} />
                                        </Form.Item>
                                        <Form.Item name="description" label="Description" rules={[{ required: true }]}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item name="category" label="Category" rules={[{ required: true }]}>
                                            <CategorySelect />
                                        </Form.Item>
                                        <Form.Item name="note" label="Note" rules={[{ required: true }]}>
                                            <TextArea rows={4} />
                                        </Form.Item>
                                        <Form.Item name="amount" label="Amount" rules={[{ required: true }]}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item name="file" label="File" getValueFromEvent={getFile}
                                            rules={[{ required: false }]}>
                                            <Upload
                                                name="avatar"
                                                listType="picture-card"
                                                className="avatar-uploader"
                                                showUploadList={false}
                                                beforeUpload={beforeUpload}
                                            >
                                                {uploadButton}
                                            </Upload>
                                        </Form.Item>

                                        <Form.Item>
                                            <Button type="primary" htmlType="submit">
                                                Submit
                                            </Button>

                                        </Form.Item>
                                    </Form>

                                </div>
                            </Card>


                        </div>

                    </Col>
                </Row>
            </div>

        </NewDocumentWrapper>
    );
};

export default Uploader;
