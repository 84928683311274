import React from 'react';
import {Select} from "antd";

const { Option } = Select;

export const CategorySelect = (opts) => {

    return <Select
        showSearch
        placeholder="Category"
        optionFilterProp="children"
        filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        filterSort={(optionA, optionB) =>
            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
        }
        {...opts}
    >
        <Option value="Advertising">Advertising</Option>
        <Option value="Continuing education">Continuing education</Option>
        <Option value="Credit and collection fees">Credit and collection fees</Option>
        <Option value="Bank fees">Bank fees</Option>
        <Option value="Dues and subscriptions">Dues and subscriptions</Option>
        <Option value="Employee benefit programs">Employee benefit programs</Option>
        <Option value="Insurance">Insurance</Option>
        <Option value="Maintenance and repairs">Maintenance and repairs</Option>
        <Option value="Legal and professional expenses">Legal and professional expenses</Option>
        <Option value="Office expenses and supplies">Office expenses and supplies</Option>
        <Option value="Telephone">Telephone</Option>
        <Option value="Utilities">Utilities</Option>
        <Option value="Postage and shipping">Postage and shipping</Option>
        <Option value="Printing">Printing</Option>
        <Option value="Rent">Rent</Option>
        <Option value="Salaries and other compensation">Salaries and other compensation</Option>
        <Option value="Travel">Travel</Option>
        <Option value="Utilities">Utilities</Option>
        <Option value="Business meals">Business meals</Option>
        <Option value="Business use of your car">Business use of your car</Option>
        <Option value="Moving expenses">Moving expenses</Option>
        <Option value="Depreciation">Depreciation</Option>
        <Option value="Charitable contributions">Charitable contributions</Option>
        <Option value="Child and/or dependent care">Child and/or dependent care</Option>
        <Option value="Startup expenses">Startup expenses</Option>
        <Option value="Mortgage interest">Mortgage interest</Option>
        <Option value="Software">Software</Option>
        <Option value="Books and magazine subscriptions">Books and magazine subscriptions</Option>
        <Option value="Foreign earned income">Foreign earned income</Option>
        <Option value="Medical expenses">Medical expenses</Option>
        <Option value="Licenses and permits">Licenses and permits</Option>
        <Option value="Manufacturing or raw materials">Manufacturing or raw materials</Option>
        <Option value="Retirement contributions">Retirement contributions</Option>
        <Option value="Real estate taxes">Real estate taxes</Option>
        <Option value="Client gifts">Client gifts</Option>
        <Option value="Employee loans">Employee loans</Option>
    </Select>
}
