import React, { useState } from 'react';
import './App.css';
import { Breadcrumb, Layout, Menu, theme } from 'antd';
import { Link, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Uploader from './components/Upload';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';

const SCOPES = 'https://www.googleapis.com/auth/drive.metadata.readonly https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/spreadsheets';


const { Header, Content, Footer } = Layout;

export const UserContext = React.createContext({ name: 'Guest' });

const App = () => {
  const [user, setUser] = useState([]);

  const [current, setCurrent] = useState('home');

  const handleClick = (e) => setCurrent(e.key);

  const login = useGoogleLogin({
    scope: SCOPES,
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log('Login Failed:', error)
  });

  // log out function to log the user out of google and set the profile array to null
  const logOut = () => {
    googleLogout();
  };

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const items = [
    {

      key: 'home',
      label: <Link to="/">Home</Link>
    },
    {
      key: 'uploader',
      label: <Link to="/uploader">Uploader</Link>

    }
  ];

  return (
    <UserContext.Provider value={user}>
      <Router>
        <Layout className="layout">
          <Header>
            <div className="logo" />
            <Menu onClick={handleClick} selectedKeys={[current]} mode="horizontal" items={items} />
          </Header>
          <Content style={{ padding: '0 50px' }}>
            <Breadcrumb style={{ margin: '16px 0' }}>
              <Breadcrumb.Item>Home</Breadcrumb.Item>
              <Breadcrumb.Item>List</Breadcrumb.Item>
              <Breadcrumb.Item>App</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-content" style={{ background: colorBgContainer }}>
              <Routes>
                <Route path="/" element={
                  <>
                    <div>Content {user.access_token}</div>
                    <button onClick={() => login()}>Sign in with Google 🚀 </button>
                  </>

                } />
                <Route path="/uploader" element={<Uploader />} />
              </Routes>
            </div>
          </Content>
          <Footer style={{ textAlign: 'center' }}>Andrea Verdesca</Footer>
        </Layout>
      </Router>
    </UserContext.Provider>


  );
};

export default App;
